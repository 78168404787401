
import {
    defineComponent, Ref, ref, PropType
} from 'vue';
import {
    getSearch, listChooseTag, ListHeaderItem, RequestData
} from '@/components/common/list';
import { access, community } from '@/data';
import accessGroupDialog from '@/components/view/pm/access-group-dialog';

const SchedulerTypeText = access.repeatLabels;
const headers: Array<ListHeaderItem> = [{
    name: 'Name',
    label: WordList.TabelPersonUserInHtmlName
}, {
    name: 'Device',
    label: WordList.TabelUpdateBoxDevice
}, {
    name: 'SchedulerType',
    label: WordList.ProperAllTextRepeats,
    type: 'customize'
}, {
    name: 'Time',
    label: WordList.ProperAllTextTime,
    type: 'customize'
}];
export default defineComponent({
    components: {
        listChooseTag,
        accessGroupDialog
    },
    props: {
        selectItem: {
            type: Array as PropType<Array<string>>,
            // eslint-disable-next-line vue/require-valid-default-prop
            default: []
        },
        roomId: {
            type: [String, null] as PropType<string | null>,
            default: null
        },
        id: {
            type: [String, null] as PropType<string | null>,
            default: null
        },
        departmentId: {
            type: [String, null] as PropType<string | null>,
            default: null
        },
        role: {
            type: [String, null] as PropType<string | null>,
            default: null
        }
    },
    setup(props, { emit }) {
        const updateToList = ref(0);
        // 兼容办公

        const isOffice = props.departmentId && props.role;
        const listRequestData: Ref<RequestData> = ref({
            url: `v3/web/${community.ProjectType.value}/access/getAll${props.roomId ? `?RoomID=${props.roomId}` : ''}${
                isOffice ? `?DepartmentID=${props.departmentId}&Role=${props.role}` : ''}${props.id ? `&ID=${props.id}` : ''}`,
            param: getSearch({})
        });
        const isShowAccessGroupDialog = ref(false);

        const multipleSelect = (ids: Array<string>, items: Array<object>) => {
            emit('watchSelectValue', ids, items);
        };

        const dealList = (data: { ID: string; UnitID: string }[]) => {
            const sysAccess: string[] = [];
            data.forEach((item) => {
                if (item.UnitID !== '0') sysAccess.push(item.ID);
            });
            emit('getSysAccess', sysAccess);
            return data;
        };

        const newSuccess = () => {
            updateToList.value += 1;
            isShowAccessGroupDialog.value = false;
        };

        return {
            headers,
            updateToList,
            listRequestData,
            multipleSelect,
            access,
            isShowAccessGroupDialog,
            SchedulerTypeText,
            newSuccess,
            dealList
        };
    }
});
